import React from 'react'
import { css } from 'linaria'
import { graphql } from 'gatsby'

import Layout from '../../components/Layout'
import { Lead, HeroHeading } from '../../components/TextElements'
import Section from '../../components/Section'
import SectionContent from '../../components/Section/Content'
import PostTile from '../../components/Blog/PostTile'

const Page = ({ location, data }) => {
  const { name } = location.state || {}
  return (
    <Layout
      location={location}
      title="Thank you!"
      image="https://bejamas-care.now.sh/**Thank%20you**.png?theme=light&md=1&fontSize=100px&images=https%3A%2F%2Fassets-bejamas.now.sh%2Fbrand%2Fsvg%2FBejamas_logo_black.svg"
    >
      <Section
        header={
          <>
            <HeroHeading>Thank you{name && `, ${name}`}!</HeroHeading>
            <Lead>
              We will get back to you <strong>within 24 hours</strong>.<br />
              In the meantime, check out these most-read blog posts.
            </Lead>
          </>
        }
      >
        <SectionContent
          wide
          columnGap="2"
          className={css`
            ${{
              gridTemplateColumns: 'repeat(auto-fill, minmax(16rem, 1fr))'
            }};
          `}
        >
          {data.posts &&
            data.posts.edges.map(({ node }) => <PostTile post={node} />)}
        </SectionContent>
      </Section>
    </Layout>
  )
}

export const query = graphql`
  query ThankYouPageQuery {
    posts: allMarkdownRemark(
      filter: {
        fields: {
          slug: {
            in: [
              "/blog/jamstack/"
              "/blog/headless-cms/"
              "/blog/static-site-generators/"
            ]
          }
        }
      }
    ) {
      edges {
        node {
          frontmatter {
            title
            excerpt
            image {
              childImageSharp {
                fluid(maxWidth: 270) {
                  ...GatsbyImageSharpFluid_withWebp_tracedSVG
                  presentationWidth
                }
              }
            }
          }
          fields {
            slug
          }
        }
      }
    }
  }
`
export default Page
